import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Guidance for Displaying Polis Data`}</h1>
    <ul>
      <li parentName="ul">{`"Agree" and "Disagree" are not opposites`}
        <ul parentName="li">
          <li parentName="ul">{`"We need more parking"`}</li>
          <li parentName="ul">{`"We do not need more parking"`}</li>
          <li parentName="ul">{`In the two `}<a parentName="li" {...{
              "href": "/comments",
              "title": "comments"
            }}>{`comments`}</a>{` above, Agree and Disagree are variously used to express the same opinion because of phrasing`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      